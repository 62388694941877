import { Badge } from 'components/shared/Badge';
import { useI18n } from 'hooks/useI18n';
import { Heading2 } from '../../shared/Typography';
import {
  StyledContainer,
  StyledRoadmap,
  StyledRoadmapItem,
  StyledRoadmapTitle
} from './LandingRoadmap.styled';

const LandingRoadmap: React.FC = () => {
  const { t } = useI18n('home');
  const roadmap: {
    quarter: string;
    title: string;
    text: string;
    currentQuarter?: boolean;
  }[] = [
    {
      quarter: t('roadmap.phases.phase1.quarter'),
      title: t('roadmap.phases.phase1.title'),
      text: t('roadmap.phases.phase1.text')
    },
    {
      quarter: t('roadmap.phases.phase2.quarter'),
      title: t('roadmap.phases.phase2.title'),
      text: t('roadmap.phases.phase2.text')
    },
    {
      quarter: t('roadmap.phases.phase3.quarter'),
      title: t('roadmap.phases.phase3.title'),
      text: t('roadmap.phases.phase3.text')
    },
    {
      quarter: t('roadmap.phases.phase4.quarter'),
      title: t('roadmap.phases.phase4.title'),
      text: t('roadmap.phases.phase4.text')
    }
  ];

  return (
    <StyledContainer>
      <Heading2 editorial>{t('roadmap.title')}</Heading2>
      <StyledRoadmap>
        {roadmap.map((r, i) => {
          return (
            <StyledRoadmapItem key={i}>
              <StyledRoadmapTitle>{r.title}</StyledRoadmapTitle>
              <p
                dangerouslySetInnerHTML={{
                  __html: r.text
                }}
              ></p>
              {r.currentQuarter && (
                <Badge variant="active" sizes="xs">
                  {t('roadmap.badge')}
                </Badge>
              )}
            </StyledRoadmapItem>
          );
        })}
      </StyledRoadmap>
    </StyledContainer>
  );
};

export default LandingRoadmap;
