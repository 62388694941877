import { Heading2 } from 'components/shared/Typography';
import styled from 'styled-components';
import { container, fontSize, screen, spacing } from '../../../theme';

export const StyledContainer = styled.div`
  max-width: ${container('xl')};
  margin: auto;
  padding: ${spacing('xl4')} ${spacing('md')};
  text-align: center;

  @media (min-width: ${screen('md')}) {
    padding-top: ${spacing('xl3')};
  }

  ${Heading2} {
    margin-bottom: ${spacing('md')};
  }

  > p {
    font-size: ${fontSize('md')};
    line-height: 1.5;

    @media (min-width: ${screen('md')}) {
      font-size: ${fontSize('lg')};
      line-height: 1.6;
    }
  }
`;

export const StyledActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacing('lg')};
  margin-top: ${spacing('xl')};
`;
