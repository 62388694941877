import styled from 'styled-components';
import {
  color,
  container,
  fontFamily,
  fontSize,
  fontWeight,
  screen,
  spacing
} from '../../../theme';

export const StyledContainer = styled.div`
  max-width: ${container('xl')};
  margin: auto;
  padding: ${spacing('xl3')} ${spacing('md')};
  text-align: center;

  @media (min-width: ${screen('lg')}) {
    padding-top: ${spacing('xl5')};
  }
`;

export const StyledRoadmap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${spacing('lg')} auto 0;

  @media (min-width: ${screen('lg')}) {
    flex-direction: row;
    align-items: stretch;
  }
`;

export const StyledRoadmapItem = styled.div`
  max-width: 25rem;
  padding-top: ${spacing('xl4')};
  padding-bottom: ${spacing('lg')};
  padding-left: ${spacing('lg')};
  padding-right: ${spacing('lg')};
  position: relative;
  font-size: ${fontSize('sm')};
  line-height: 1.42;
  text-align: center;

  @media (min-width: ${screen('lg')}) {
    width: 25%;
    padding-left: ${spacing('xl3')};
    padding-top: ${spacing('xl')};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -${spacing('xl')};
    left: 0;
    right: 0;
    height: ${spacing('xl')};
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    z-index: 1;

    @media (min-width: ${screen('lg')}) {
      top: 0;
      bottom: 0;
      left: unset;
      right: -${spacing('xl')};
      height: unset;
      width: ${spacing('xl')};
      clip-path: polygon(0 0, 100% 50%, 0 100%);
    }
  }

  &:first-of-type {
    background-color: ${color('navy_100')};

    &::after {
      background-color: ${color('navy_100')};
    }
  }

  &:nth-of-type(2) {
    background-color: ${color('navy_200')};

    &::after {
      background-color: ${color('navy_200')};
    }
  }

  &:nth-of-type(3) {
    background-color: ${color('navy_400')};

    &::after {
      background-color: ${color('navy_400')};
    }
  }

  &:last-of-type {
    background-color: ${color('navy_500')};

    &::after {
      background-color: ${color('navy_500')};
    }
  }

  &:nth-of-type(n + 3) {
    color: ${color('white')};
  }
`;

export const StyledRoadmapTitle = styled.h3`
  margin-top: ${spacing('md')};
  margin-bottom: ${spacing('md')};
  font-family: ${fontFamily('display')};
  font-size: ${fontSize('xl2')};
  line-height: 1;
  font-weight: ${fontWeight('semiBold')};
  text-transform: uppercase;

  @media (min-width: ${screen('lg')}) {
    min-height: 2em;
  }
`;
