import { faSignIn } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LandingAnimation from 'components/marketingSite/landing/LandingAnimation';
import { ButtonLink } from 'components/shared/ButtonLink';
import { useI18n } from 'hooks/useI18n';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import Fade from 'react-reveal/Fade';
import { Link as LinkScroll } from 'react-scroll';
import ServiceEventTracking from 'services/service.event-tracking';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import {
  StyledHero,
  StyledHeroActions,
  StyledHeroContainer,
  StyledHeroText,
  StyledHeroTitle
} from './LandingHero.styled';

const LandingHero: React.FC = () => {
  const { t } = useI18n('home');
  const { locale } = useRouter();

  return (
    <StyledHero>
      <StyledHeroContainer>
        <StyledHeroText>
          <Fade bottom delay={100}>
            <StyledHeroTitle>{t('hero.title')}</StyledHeroTitle>
          </Fade>
          <Fade bottom delay={200}>
            <div
              dangerouslySetInnerHTML={{
                __html: t('hero.desc')
              }}
            ></div>
          </Fade>
          <StyledHeroActions>
            <Link
              href={`${getAppUrl(
                APP_URLS.join,
                locale
              )}?locale=${locale}&source=unete-hero-home`}
              passHref
            >
              <ButtonLink
                variant="brandTalent"
                sizes="md"
                onClick={(e) => {
                  ServiceEventTracking.trackExternalLandingCtaClicked(
                    e,
                    `${getAppUrl(
                      APP_URLS.join,
                      locale
                    )}?locale=${locale}&source=unete-hero-home`,
                    'unete-hero-home'
                  );
                  ServiceEventTracking.track({
                    type: 'Website - Home CTA Clicked',
                    properties: {
                      buttonName: 'main_home_signup'
                    }
                  });
                }}
              >
                <FontAwesomeIcon icon={faSignIn} />
                <span>{t('hero.actions.signUp')}</span>
              </ButtonLink>
            </Link>

            <LinkScroll to="manifesto" href="#manifesto" offset={-150}>
              <ButtonLink sizes="md" isLinkDark>
                <span>{t('hero.actions.manifesto')}</span>
              </ButtonLink>
            </LinkScroll>
          </StyledHeroActions>
        </StyledHeroText>

        <LandingAnimation />
      </StyledHeroContainer>
    </StyledHero>
  );
};

export default LandingHero;
