import LandingComponent from 'components/marketingSite/landing/LandingComponent';
import HeadMetadata from 'components/shared/HeadMetadata';
import { useI18n } from 'hooks/useI18n';
import { usePageTrack } from 'hooks/usePageTrack';
import { WithBaseUrl } from 'interfaces/ui/with-base-url';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { APP_URLS } from 'utils/app-urls';
import { config } from 'utils/config';
import { getPublicPath } from 'utils/paths';

type LandingPageProps = WithBaseUrl;

const LandingPage: React.FC<LandingPageProps> = ({ baseUrl }) => {
  const { t } = useI18n('home');

  usePageTrack('Website - Page Loaded');

  return (
    <>
      <HeadMetadata
        title={t('title')}
        desc={t('description')}
        url={APP_URLS.home}
        imgUrl={`${baseUrl}${getPublicPath('/images/cards/card_home.png')}`}
      />
      <LandingComponent />
    </>
  );
};

export default LandingPage;

export const getStaticProps: GetStaticProps<LandingPageProps> = async ({
  locale
}) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || '', [
        'common',
        'topbar',
        'footer',
        'home'
      ])),
      baseUrl: config.appUrl
    },
    revalidate: 30
  };
};
