import styled from 'styled-components';
import {
  color,
  container,
  fontFamily,
  fontSize,
  fontWeight,
  screen,
  spacing
} from '../../../theme';
import { Heading2 } from '../../shared/Typography';

export const StyledManifest = styled.div`
  max-width: ${container('xl')};
  margin: auto;
  padding: ${spacing('xl')} ${spacing('md')};

  @media (min-width: ${screen('md')}) {
    padding-top: ${spacing('xl5')};
    padding-bottom: ${spacing('xl3')};
  }
`;

export const StyledManifestIntro = styled.div`
  text-align: center;

  p {
    font-size: ${fontSize('md')};
    line-height: 1.5;

    @media (min-width: ${screen('md')}) {
      font-size: ${fontSize('lg')};
      line-height: 1.6;
    }
  }
`;

export const StyledManifestTitle = styled(Heading2)`
  margin-bottom: ${spacing('md')};
`;

export const StyledManifestItem = styled.div`
  text-align: center;
  font-family: ${fontFamily('display')};
  font-weight: ${fontWeight('light')};
  text-transform: uppercase;
  font-size: ${fontSize('xl2')};
  line-height: 1;
  color: ${color('emerald_900')};
  padding: 1.25rem;

  p {
    margin: 0;
  }

  strong {
    font-weight: ${fontWeight('semiBold')};

    @media (max-width: ${screen('mdMax')}) {
      display: block;
    }
  }
`;

export const StyledManifestList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('sm')};

  @media (max-width: ${screen('mdMax')}) {
    margin-top: ${spacing('xl')};
  }

  > .react-reveal:first-of-type ${StyledManifestItem} {
    background-color: ${color('mint_900')};
  }

  > .react-reveal:nth-of-type(2) ${StyledManifestItem} {
    background-color: ${color('mint_700')};
  }

  > .react-reveal:nth-of-type(3) ${StyledManifestItem} {
    background-color: ${color('mint_500')};
  }

  > .react-reveal:last-of-type ${StyledManifestItem} {
    background-color: ${color('mint_300')};
  }
`;
