import { Badge } from 'components/shared/Badge';
import { LinkText } from 'components/shared/LinkText';
import { useI18n } from 'hooks/useI18n';
import { Trans } from 'next-i18next';
import { useRouter } from 'next/dist/client/router';
import Fade from 'react-reveal/Fade';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { getPublicPath } from 'utils/paths';
import {
  StyledContainer,
  StyledIcon,
  StyledItem,
  StyledTitle
} from './LandingFeatures.styled';

const LandingFeatures: React.FC = () => {
  const { t } = useI18n('home');
  const { locale } = useRouter();

  const items = [
    {
      icon: 'feature01',
      title: t('features.featureItems.feature01.title'),
      text: t('features.featureItems.feature01.text'),
      comingSoon: false
    },
    {
      icon: 'feature02',
      title: t('features.featureItems.feature02.title'),
      text: t('features.featureItems.feature02.text'),
      comingSoon: true
    },
    {
      icon: 'feature03',
      title: t('features.featureItems.feature03.title'),
      text: t('features.featureItems.feature03.text'),
      comingSoon: false
    },
    {
      icon: 'feature04',
      title: t('features.featureItems.feature04.title'),
      comingSoon: false,
      text: (
        <Trans
          ns={'home'}
          i18nKey={'features.featureItems.feature04.text'}
          components={{
            privacyPolicy: (
              <LinkText href={getAppUrl(APP_URLS.privacyPolicy, locale)} />
            )
          }}
        ></Trans>
      )
    }
  ];

  return (
    <StyledContainer>
      {items.map((item, index) => {
        return (
          <Fade key={index} bottom cascade distance="40px" delay={index * 400}>
            <StyledItem>
              <StyledIcon>
                <img
                  src={getPublicPath('/images/landing/' + item.icon + '.svg')}
                  alt=""
                />
                {item.comingSoon && (
                  <Badge variant="secondary" sizes="xs">
                    {t('features.commingSoon')}
                  </Badge>
                )}
              </StyledIcon>
              <StyledTitle>{item.title}</StyledTitle>
              <p>{item.text}</p>
            </StyledItem>
          </Fade>
        );
      })}
    </StyledContainer>
  );
};

export default LandingFeatures;
