import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  StyledFaqs,
  StyledFaqsButton,
  StyledFaqsContent,
  StyledFaqsIcon,
  StyledFaqsQuestion,
  StyledFaqsText,
  StyledFaqsTitle
} from 'components/jobOffer/faqs/Faqs.styled';
import { useEffect, useRef, useState } from 'react';
import { Element } from 'react-scroll';

export type FaqProps = {
  question: string;
  answer: string | React.ReactNode;
  active?: boolean;
  renderComponent?: boolean;
  anchor?: string;
};

const FaqsItem: React.FC<FaqProps> = ({
  question,
  answer,
  active,
  renderComponent,
  anchor
}) => {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');

  const content = useRef<HTMLElement>(null);

  function toggleAccordion(): void {
    setActiveState(setActive === '' ? 'is-active' : '');
    if (content !== null) {
      if (content.current !== null) {
        setHeightState(
          setActive === 'is-active'
            ? '0px'
            : `${content.current.scrollHeight}px`
        );
      }
    }
  }

  useEffect(() => {
    if (active) {
      setTimeout(() => {
        toggleAccordion();
      }, 500);
    }
  }, [active]);

  return (
    <StyledFaqs>
      <StyledFaqsQuestion>
        <StyledFaqsButton className={`${setActive}`} onClick={toggleAccordion}>
          <StyledFaqsTitle>
            {anchor && <Element name={anchor} />}
            {question}
          </StyledFaqsTitle>
          <StyledFaqsIcon>
            <FontAwesomeIcon icon={faCaretDown} />
          </StyledFaqsIcon>
        </StyledFaqsButton>
      </StyledFaqsQuestion>

      <StyledFaqsContent ref={content} style={{ maxHeight: `${setHeight}` }}>
        <StyledFaqsText>
          {!renderComponent && typeof answer === 'string' && (
            <div dangerouslySetInnerHTML={{ __html: answer || '' }}></div>
          )}
          {renderComponent && <div>{answer}</div>}
        </StyledFaqsText>
      </StyledFaqsContent>
    </StyledFaqs>
  );
};

export default FaqsItem;
