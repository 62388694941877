import { faSignIn } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonLink from 'components/shared/ButtonLink.styled';
import { Heading2 } from 'components/shared/Typography';
import { useI18n } from 'hooks/useI18n';
import { Trans } from 'next-i18next';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import ServiceEventTracking from 'services/service.event-tracking';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { StyledActions, StyledContainer } from './LandingCV.styled';

const LandingCV: React.FC = () => {
  const { t } = useI18n('home');
  const { locale } = useRouter();

  return (
    <StyledContainer>
      <Heading2 editorial>{t('cv.title')}</Heading2>
      <Trans
        ns={'home'}
        i18nKey={'cv.desc'}
        components={{
          mac: (
            <a href="https://github.com/getmanfred/mac" target={'-blank'}></a>
          )
        }}
      ></Trans>
      <StyledActions>
        <Link
          href={`${getAppUrl(
            APP_URLS.join,
            locale
          )}?locale=${locale}&source=unete-home`}
          passHref
        >
          <ButtonLink
            variant="brandTalent"
            sizes="md"
            onClick={(e) => {
              ServiceEventTracking.track({
                type: 'Website - Home CTA Clicked',
                properties: {
                  buttonName: 'CVSection_home_signup'
                }
              });

              ServiceEventTracking.trackExternalLandingCtaClicked(
                e,
                `${getAppUrl(
                  APP_URLS.join,
                  locale
                )}?locale=${locale}&source=unete-home`,
                'unete-home'
              );
            }}
          >
            <FontAwesomeIcon icon={faSignIn} />
            <span>{t('cv.CTA')}</span>
          </ButtonLink>
        </Link>
      </StyledActions>
    </StyledContainer>
  );
};

export default LandingCV;
