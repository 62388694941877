import FaqsItem, { FaqProps } from 'components/marketingSite/shared/FaqsItem';
import { Heading2, Heading3 } from 'components/shared/Typography';
import {
  StyledFaqsHeader,
  StyledFaqsSection,
  StyledWebFaqs
} from './Faqs.styled';

type FaqsProps = {
  faqs: FaqProps[];
  title?: string | null;
  desc?: string | React.ReactNode;
  h3?: boolean;
};
const Faqs: React.FC<FaqsProps> = ({ faqs, title, desc, h3 }) => {
  return (
    <>
      {faqs && faqs.length > 0 && (
        <StyledFaqsSection>
          <StyledFaqsHeader>
            {!h3 && title && <Heading2 editorial>{title}</Heading2>}

            {h3 && title && <Heading3 editorial>{title}</Heading3>}
            {desc && typeof desc === 'object' && <p>{desc}</p>}
            {typeof desc === 'string' && (
              <p dangerouslySetInnerHTML={{ __html: desc || '' }}></p>
            )}
          </StyledFaqsHeader>
          <StyledWebFaqs>
            {faqs?.map((faq, index) => {
              return (
                <FaqsItem
                  renderComponent={faq.renderComponent}
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  anchor={faq.anchor}
                  active={faq.active}
                />
              );
            })}
          </StyledWebFaqs>
        </StyledFaqsSection>
      )}
    </>
  );
};

export default Faqs;
