import styled from 'styled-components';
import { getPublicPath } from 'utils/paths';
import { color, container, fontSize, screen, spacing } from '../../../theme';
import { Heading1 } from '../../shared/Typography';

export const StyledHero = styled.div`
  background-image: ${() =>
    `url('${getPublicPath('/images/landing/bg-hero.svg')}')`};
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 175% 100%;
  padding: ${spacing('xl')} ${spacing('xl')} 0;

  @media (min-width: ${screen('md')}) {
    padding-top: ${spacing('xl4')};
    background-size: cover;
    background-position: center bottom;
  }
`;

export const StyledHeroContainer = styled.div`
  max-width: ${container('xl')};
  margin: auto;
  padding-top: ${spacing('xl4')};
  display: flex;
  flex-direction: column;
  gap: ${spacing('md')};

  @media (min-width: ${screen('md')}) {
    flex-direction: row;
  }
`;

export const StyledHeroText = styled.div`
  flex-basis: 50%;
  color: ${color('text_white')};
  font-size: ${fontSize('md')};
  line-height: 1.5;

  @media (min-width: ${screen('md')}) {
    margin-bottom: ${spacing('xl5')};
    font-size: ${fontSize('lg')};
    line-height: 1.4;
  }

  p {
    max-width: 26.25rem;
  }
`;

export const StyledHeroTitle = styled(Heading1)`
  margin-bottom: ${spacing('lg')};
  max-width: 6em;

  @media (min-width: ${screen('md')}) {
    max-width: unset;
  }
`;

export const StyledHeroActions = styled.div`
  margin-top: ${spacing('xl')};
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing('sm')} ${spacing('lg')};

  @media (min-width: ${screen('md')}) {
    margin-top: ${spacing('xl3')};
  }
`;

export const StyledHeroImage = styled.div`
  flex-basis: 50%;
`;
