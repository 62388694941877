import styled, { css } from 'styled-components';
import { color, fontSize, screen, spacing } from '../../../theme';
import { StyledFaqsHeader, StyledFaqsSection } from '../shared/Faqs.styled';

export const StyledLanding = styled.div<{ withNotification: boolean }>`
  ${({ withNotification }) =>
    css`
      margin-top: -${spacing(withNotification ? 'l' : 'xl4')};
    `}
`;

export const StyledFaqs = styled.div`
  background-color: ${color('midnight_100')};

  ${StyledFaqsSection} {
    padding-top: ${spacing('xl4')};
    padding-bottom: ${spacing('xl4')};
  }

  ${StyledFaqsHeader} {
    font-size: ${fontSize('md')};
    line-height: 1.5;

    @media (min-width: ${screen('md')}) {
      font-size: ${fontSize('lg')};
      line-height: 1.6;
    }
  }
`;
