import { Heading2 } from 'components/shared/Typography';
import styled from 'styled-components';
import { color, container, fontSize, screen, spacing } from '../../../theme';

export const StyledFaqsSection = styled.div`
  max-width: ${container('md')};
  width: 100%;
  margin: auto;
  padding: ${spacing('xl6')} ${spacing('md')};
`;

export const StyledFaqsHeader = styled.div`
  text-align: center;
  max-width: ${container('sm')};
  font-size: ${fontSize('lg')};
  margin-bottom: ${spacing('lg')};

  ${Heading2} {
    margin-bottom: ${spacing('md')};
  }

  @media (min-width: ${screen('md')}) {
    margin-bottom: ${spacing('xl4')};
  }
`;

export const StyledWebFaqs = styled.dl`
  margin: auto;
  max-width: ${container('md')};
  border-bottom: 1px solid ${color('midnight_200')};
  counter-reset: faqs;
`;
