import { Badge } from 'components/shared/Badge';
import styled from 'styled-components';
import {
  color,
  container,
  fontSize,
  fontWeight,
  screen,
  spacing
} from '../../../theme';

export const StyledContainer = styled.div`
  max-width: ${container('xl')};
  margin: auto;
  padding: ${spacing('xl')} ${spacing('md')};
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacing('xl')};

  @media (min-width: ${screen('md')}) {
    grid-template-columns: 1fr 1fr;
    padding-top: ${spacing('xl4')};
  }
`;

export const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.5;

  > :last-child {
    margin-bottom: 0;
  }
`;

export const StyledIcon = styled.div`
  position: relative;

  img {
    width: 5rem;
    height: 5rem;
    display: block;
  }

  ${Badge} {
    position: absolute;
    top: 50%;
    left: ${spacing('lg')};
    transform: translateY(-50%);
  }
`;

export const StyledTitle = styled.h3`
  margin-top: ${spacing('md')};
  margin-bottom: ${spacing('md')};
  font-size: ${fontSize('lg')};
  line-height: 1.2;
  font-weight: ${fontWeight('black')};

  &::before {
    content: '';
    display: block;
    width: ${spacing('xl')};
    height: ${spacing('xs2')};
    background-color: ${color('cyan_700')};
    margin-bottom: ${spacing('md')};
  }

  ~ p {
    margin-top: 0;
  }
`;
