import { useRouter } from 'next/router';
import Lottie from 'react-lottie-player';
import animationTestEN from './animationTest_en.json';
import animationTestES from './animationTest_es.json';
import { StyledHeroImage } from './LandingHero.styled';

export const LandingAnimation: React.FC = () => {
  const { locale } = useRouter();

  return (
    <StyledHeroImage>
      <Lottie
        loop
        animationData={locale === 'es' ? animationTestES : animationTestEN}
        play
      />
    </StyledHeroImage>
  );
};

export default LandingAnimation;
