import styled from 'styled-components';
import {
  color,
  fontFamily,
  fontSize,
  fontWeight,
  screen,
  spacing
} from '../../../theme';

export const StyledFaqs = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledFaqsQuestion = styled.dt`
  border-top: 1px solid ${color('midnight_200')};
`;

export const StyledFaqsTitle = styled.h3`
  margin: 0;
  font-family: ${fontFamily('sans')};
  font-size: ${fontSize('sm')};
  font-weight: ${fontWeight('black')};
  display: flex;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('lg')};
  }

  &::before {
    counter-increment: faqs;
    content: counter(faqs) '.';
    color: ${color('text_placeholder')};
    display: block;
    margin-right: ${spacing('xs')};
  }
`;

export const StyledFaqsButton = styled.button`
  -webkit-appearance: none;
  color: inherit;
  cursor: pointer;
  width: 100%;
  padding-top: ${spacing('sm')};
  padding-bottom: ${spacing('sm')};
  padding-left: ${spacing('sm')};
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  outline: none;
  text-align: left;

  &:focus-visible {
    ${StyledFaqsTitle}:before {
      color: ${color('active')};
    }
  }
`;

export const StyledFaqsIcon = styled.div`
  margin-left: auto;
  padding-left: ${spacing('md')};

  svg {
    width: ${spacing('md')} !important;
    height: ${spacing('md')};
    transition: transform 0.3s ease-in-out;

    .is-active & {
      transform: rotate(180deg);
      transform-origin: center center;
    }
  }
`;

export const StyledFaqsContent = styled.dd`
  overflow: hidden;
  margin: 0;
  transition: max-height 0.3s ease-in-out;
`;

export const StyledFaqsText = styled.div`
  padding-left: 2.5rem;
  padding-bottom: ${spacing('md')};

  p {
    margin: 0;
    &:not(:first-child) {
      margin-top: ${spacing('md')};
    }
  }

  ul, ol {
    margin-left: 2rem;
  }

  li {
    list-style-type: disc;
    margin-top: ${spacing('md')};
  }

  ol li {
    list-style-type: decimal;
  }

  img {
    display: block;
    margin-top: ${spacing('md')};
    margin-left: auto;
    margin-right: auto;
    
    &:not(:last-child){
      margin-bottom: ${spacing('md')};
    }
  }
`;
