import { useI18n } from 'hooks/useI18n';
import Fade from 'react-reveal/Fade';
import { Element } from 'react-scroll';
import {
  StyledManifest,
  StyledManifestIntro,
  StyledManifestItem,
  StyledManifestList,
  StyledManifestTitle
} from './LandingManifest.styled';

const LandingManifest: React.FC = () => {
  const { t } = useI18n('home');

  const items = [
    {
      text: t('manifest.items.peopleFirst')
    },
    {
      text: t('manifest.items.honesty')
    },
    {
      text: t('manifest.items.transparency')
    },
    {
      text: t('manifest.items.closeness')
    }
  ];

  return (
    <StyledManifest>
      <StyledManifestIntro>
        <Element id="manifesto" name="manifesto">
          <StyledManifestTitle editorial>
            {t('manifest.title')}
          </StyledManifestTitle>
        </Element>
        <p>{t('manifest.desc')}</p>
      </StyledManifestIntro>

      <StyledManifestList>
        {items.map((item, index) => {
          return (
            <Fade
              key={index}
              bottom
              cascade
              distance="40px"
              delay={index * 400}
            >
              <StyledManifestItem>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(`${item.text}`)
                  }}
                ></p>
              </StyledManifestItem>
            </Fade>
          );
        })}
      </StyledManifestList>
      <StyledManifestIntro>
        <p>{t('manifest.footer')}</p>
      </StyledManifestIntro>
    </StyledManifest>
  );
};

export default LandingManifest;
